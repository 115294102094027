:root {
  --primaryColor: #f17d4f;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .navbar-content,
.m-header {
  background-color: #112034 !important;
} */

/* .nxl-navigation { */
/* display: none !important; */
/* } */

.text-primary {
  /* color: #a060ff !important; */
  color: #f17d4f !important;
}

.bg-nf-secondary {
  /* background-color: #ff488b !important; */
  background-color: #072b5c !important;
}
.bg-primary {
  background-color: #f17d4f !important;
}

.bg-paper {
  background-image: url("../public/assets/images/chat-bg-backup.jpg");
}

.pass-container.focused {
  border: 1px solid #a060ff;
  border-color: #3454d1 !important;
}

.pass:focus {
  color: #a060ff !important;
}

.pass-container.focused .pass {
  border: 1px solid #3454d1;
  /* border-color: #3454d1 !important; */
  color: #a060ff !important;
}

.pass-container.focused input:focus {
  border-color: transparent !important;
}

.btn-primary,
.btn-primary:hover {
  border-color: #f17d4f !important;
  background-color: #f17d4f !important;
}

.bg-primary-light {
  background-color: #a060ff33;
}

.text-tertiary {
  color: #ff488b;
}

.text-green {
  color: #1e9f38;
}

.otp-input {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 1.2rem;
  border-radius: 4px;
}

.border-primary {
  border: 1px solid var(--primaryColor) !important;
}

.ql-container {
  min-height: 100px;
}

.a-div:hover {
  color: #a060ff;
}

.home svg {
  height: 50px !important;
}

.icon-sm {
  height: 10px !important;
}

svg.title-icons {
  height: 70px !important;
}

svg {
  height: 20px;
}
.small-svg {
  height: 12px !important;
}

.icon-large {
  height: 48px;
  width: 48px;
}

textarea {
  color: #283c50;
  padding: 12px 15px;
  border-color: #e5e7eb;
  border-radius: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.zip-container {
  border: 1px dashed #ecedf4;
  border-color: #ff488b;
}

.btn-tertiary,
.btn-tertiary:hover,
.btn-tertiary:focus {
  background-color: #ff488b;
  color: white;
}

.nxl-container.apps-email
  .nxl-content
  .main-content
  .content-area
  .items-details {
  position: relative !important;
  visibility: visible !important;
  transform: none !important;
  opacity: 1 !important;
  z-index: 1 !important;
}

.isScrollable {
  overflow-y: auto;
  height: calc(100vh - 155px);
}

html.app-skin-dark textarea {
  color: white;
}

.css-ybpey0-control {
  cursor: pointer !important;
}

.PhoneInputInput {
  border: 0 !important;
  padding: 0 !important;
}

.auth-minimal-wrapper .auth-minimal-inner .minimal-card-wrapper {
  max-width: 500px !important;
}

/* public styles */
.contain-img {
  object-fit: contain;
}

.head-bg {
  background-image: url("./assets/images/bg6.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  position: relative;
}

.middle-bg {
  background: url("./assets/images/bg4.png") no-repeat center center;
  background-size: cover;
  min-height: 100vh;
  position: relative;
}

.third-bg {
  background-image: url("./assets/images/bg5.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  position: relative;
}

/* blur effect for smooth transition of background  */

.middle-bg::after,
.head-bg::after,
.third-bg::before,
.third-bg::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 50px; /* Adjust height as needed */
  z-index: 1;
}

/* .head-bg::before{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 50px; 
} */

.middle-bg::before,
/* .head-bg::before, */
.third-bg::before {
  top: 0;
  background: linear-gradient(
    to bottom,
    #f3f4f6 10%,
    rgba(255, 255, 255, 0) 100%
  );
}

.middle-bg::after,
.head-bg::after,
.third-bg::after {
  bottom: 0;
  background: linear-gradient(to top, #f3f4f6 10%, rgba(255, 255, 255, 0) 100%);
}

/* blur effect for smooth transition of background  */

.section-1 {
  margin-top: 3rem;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-list-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0; /* Optional: Add some padding if needed */
}

.image-list-container .MuiImageList-root {
  display: flex;
}

.image-list-container .MuiImageListItem-root {
  display: inline-block;
  margin-right: 16px; /* Adjust margin as needed */
}

@media (min-width: 320px) {
  .head-bg {
    min-height: 50vh;
    padding-bottom: 0.5rem;
  }

  .center {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .head-bg,
  .middle-bg,
  .third-bg {
    min-height: auto;
    margin-bottom: 2rem;
  }

  .head-bg {
    padding-top: 1rem;
  }
}

@media (min-width: 1024px) {
  .head-bg,
  .middle-bg,
  .third-bg {
    min-height: 100vh;
  }

  .section-2 {
    margin: 3rem auto;
  }

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .middle-bg,
  .third-bg {
    margin-top: 1rem;
  }

  /* slider  */
  .slider-container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
  }

  .slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .slide {
    min-width: 150px;
    margin: 0 10px;
  }

  .slide img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1000;
  }

  .prev {
    left: 10px;
  }

  .next {
    right: 10px;
  }
}

@media (min-width: 1400px) {
  .section-2 {
    margin: auto;
  }
}

@media (min-width: 1024px) {
}

/* receipt css  */
.text-danger strong {
  color: #9f181c;
}
.receipt-main {
  background: #ffffff none repeat scroll 0 0;
  border-bottom: 12px solid #072b5c;
  border-top: 12px solid #f17d4f;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 40px 30px !important;
  position: relative;
  box-shadow: 0 1px 21px #acacac;
  color: #333333;
  font-family: open sans;
}
.receipt-main p {
  color: #333333;
  font-family: open sans;
  line-height: 1.42857;
}
.receipt-footer h1 {
  font-size: 15px;
  font-weight: 400 !important;
  margin: 0 !important;
}
.receipt-main::after {
  background: #072b5c none repeat scroll 0 0;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: -13px;
}
.receipt-main thead {
  background: #072b5c none repeat scroll 0 0;
}
.receipt-main thead th {
  color: #fff;
}
.receipt-right h5 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 7px 0;
}
.receipt-right p {
  font-size: 12px;
  margin: 0px;
}
.receipt-right p i {
  text-align: center;
  width: 18px;
}
.receipt-main td {
  padding: 9px 20px !important;
}
.receipt-main th {
  padding: 13px 20px !important;
}
.receipt-main td {
  font-size: 13px;
  font-weight: initial !important;
}
.receipt-main td p:last-child {
  margin: 0;
  padding: 0;
}
.receipt-main td h2 {
  font-size: 20px;
  font-weight: 900;
  margin: 0;
  text-transform: uppercase;
}
.receipt-header-mid .receipt-left h1 {
  font-weight: 100;
  margin: 34px 0 0;
  text-align: right;
  text-transform: uppercase;
}
.receipt-header-mid {
  margin: 24px 0;
  overflow: hidden;
}

#container {
  background-color: #dcdcdc;
}
