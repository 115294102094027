/* navigation */

.navigation {
  display: flex;
}

.navigation .nxl-caption {
  padding: 15px 20px 10px;
}

.sidebar {
  width: 280px;
  position: fixed;
  top: 0;
  bottom: 0;
  transition: width 0.3s ease;
  z-index: 1025;
}

.sidebar .m-header {
  z-index: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  height: 80px;
  background: #fff;
  border-right: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
}

.sidebar .card {
  margin: 20px;
  color: #283c50;
  background: #eff0f6;
  border-color: #e5e7eb;
}

.mini-menu .sidebar {
  width: 100px;
}

.header {
  height: 80px;
  position: fixed;
  top: 0;
  left: 280px;
  right: 0;
  transition: left 0.3s ease;
  z-index: 1025;
}

.mini-menu .header {
  left: 100px;
}

.menu-toggle {
  cursor: pointer;
  padding: 20px;
}

.mini-menu .nxl-container {
  margin-left: 100px;
}

.mini-menu .sidebar .nxl-link {
  display: flex;
  justify-content: center;
}

.mini-menu .sidebar .card {
  display: none;
}

.mini-menu .sidebar .nxl-mtext,
.mini-menu .sidebar .nxl-arrow {
  display: none;
  transition: all 0.3s ease;
}

.fs-10 {
  font-size: 10px;
}

.sidebar .nxl-link {
  margin: 3px 15px;
  border-radius: 5px;
  line-height: 1.2;
  padding: 10px 15px;
  font-size: 13px;
  color: #001327;
  display: block;
}

.sidebar .nxl-item {
  list-style-type: none;
}

.sidebar .nxl-mtext {
  font-weight: 600;
}

.sidebar .nxl-item.active > .nxl-link,
.nxl-navigation .navbar-content .nxl-navbar > .nxl-item:hover > .nxl-link,
.sidebar .nxl-link.active {
  background-color: #eaebef;
  transition: all 0.3s ease;
  color: #001327;
}

.sidebar .nxl-link:hover {
  color: #001327;
  background-color: #eaebef;
  transition: all 0.3s ease;
}

.sidebar .navbar-content {
  padding: 10px 0;
  position: relative;
  border-right: 1px solid #e5e7eb;
  height: calc(100vh - 80px);
  overflow: hidden;
}

.nxl-arrow {
  float: right;
  transition: all 0.2s ease-in-out;
}

.nxl-submenu {
  display: none;
  transition: 0.3s ease-out;
}
.nxl-submenu.open {
  display: block;
}

.header .user-avtar,
.header .user-image {
  height: 35px;
  border-radius: 50%;
}

/* expanded area  */
.sidebar.expanded {
  width: 280px;
}

.sidebar.expanded .nxl-mtext,
.sidebar.expanded .nxl-arrow {
  display: inline-block;
}

@media screen and (max-width: 450px) {
  .mini-menu .sidebar {
    width: 0px;
  }

  .mini-menu .header {
    left: 0;
  }

  .mini-menu .nxl-container {
    margin-left: 0;
  }

  .left-header {
    display: none !important;
  }
}
