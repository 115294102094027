.App {
  text-align: center;
}

.loader {
  width: 15px;
  height: 15px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-right: 5px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.qr-placeholder {
  position: relative;
  display: inline-block;
}

.qr-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  text-align: center;
  z-index: 10;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: to dim the background more */
}

.qr-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--primaryColor);
  border-radius: 50%;
  animation: spin 2s linear infinite;
  z-index: 10;
}

.qr-text {
  margin-top: 10px; /* Space between spinner and text */
  z-index: 10;
}

/* Spinner Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swiper {
  height: 150px;
  width: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 150px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

/* /sideheader */
/* .m-header {
  height: 80px;
}

.nxl-caption {
  display: none;
}

.logo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-full,
.logo-icon {
  position: absolute;
  transition: opacity 0.4s ease, transform 0.4s ease; 
}

.logo-full {
  opacity: 1;
  transform: scale(1);
}

.logo-icon {
  opacity: 0;
  transform: scale(0.8); 
}

.hidden {
  opacity: 0;
  transform: scale(0.8); 
}

.visible {
  opacity: 1;
  transform: scale(1); 
}

.side-nav {
  width: 100px;
  position: fixed;

  transition: width 0.3s ease, transform 0.3s ease;
  z-index: 1000;
}

.navbar-content {
  height: calc(100vh - 80px);
  overflow-y: auto;
  padding: 10px 0;
}

.side-nav.expanded .logo-full,
.side-nav.visible .logo-full {
  opacity: 1;
  transform: scale(1);
}

.side-nav.expanded .logo-icon,
.side-nav.visible .logo-icon {
  opacity: 0;
  transform: scale(0.8);
}

.collapsed .side-nav {
  width: 280px;
}

.collapsed .side-nav .nxl-caption,
.side-nav.visible .nxl-caption {
  display: flex;
}

.side-nav.expanded {
  position: absolute;
  width: 280px;
}

.head-nav {
  min-height: 80px;
  position: fixed;
  left: 100px;
  right: 0;
  position: fixed;
}

.collapsed .head-nav {
  left: 280px;
}

.toggle {
  display: none;
}

.collapsed .side-nav .nufi-link,
.side-nav.expanded .nufi-link,
.side-nav.visible .nufi-link {
  margin: 3px 15px;
  border-radius: 5px;
}

.collapsed .side-nav .nufi-link .nxl-mtext,
.collapsed .side-nav .nufi-link .nxl-arrow,
.side-nav.expanded .nufi-link .nxl-arrow,
.side-nav.visible .nufi-link .nxl-arrow,
.side-nav.expanded .nufi-link .nxl-mtext,
.side-nav.expanded .nufi-link .nxl-mtext,
.side-nav.visible .nufi-link .nxl-mtext,
.side-nav.visible .nufi-link .nxl-mtext {
  display: inline-block;
}

.nufi-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.2;
  padding: 10px 15px;
  font-size: 13px;
  color: #283c50;
  font-weight: 600;
  transition: all 0.3s ease;
  margin: 0 20px;
  border-radius: 6px 6px;
}

.nxl-micon {
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
}

.custom-nav .nxl-mtext,
.custom-nav .nxl-arrow {
  display: none;
  transition: all 0.3s ease;
}

.nxl-item.active .nufi-link,
.nxl-item:hover > .nufi-link {
  color: #001327;
  background-color: #eaebef;
  transition: all 0.3s ease;
}

.navbar-content .nxl-submenu .nufi-link {
  font-size: 0.8rem;
  padding: 10px 20px;
  margin-left: 35px !important;
  margin-right: 5px !important;
}

.nxl-submenu {
  display: none;
  transition: 0.3s ease-out;
}

.side-nav.expanded .card,
.side-nav.visible .card,
.collapsed .side-nav .card {
  display: block !important;
}

.nxl-submenu.open {
  display: block;
}

.side-nav.expanded .nxl-submenu {
  display: block !important;
}

@media screen and (max-width: 991px) {
  .side-nav {
    width: 280px;
    transform: translateX(-100%);
    position: fixed;
  }

  .head-nav {
    left: 0 !important;
  }

  .hamburger {
    display: none;
  }
  .toggle {
    display: block;
  }

  .navigation.collapsed .side-nav.visible,
  .navigation .side-nav.visible {
    position: absolute;
    width: 280px;
    transform: translateX(0);
  }
} */
