/* Loader_tOverlay.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of other elements */
}

.loader_t {
  width: 100px;
  height: 75px;
  margin: 0 auto;
  background: #fff;
  position: relative;
  border-radius: 100%;
}
.loader_t:before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  border: 15px solid transparent;
  border-top: 25px solid #fff;
  transform: rotate(45deg);
  top: 50px;
  left: -15px;
}

.loader_t:after {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #a060ff;
  box-shadow: 20px 0 #a060ff, -20px 0 #a060ff;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: rgba(160, 95, 255, 0.25);
    box-shadow: 20px 0 rgba(160, 95, 255, 0.25), -20px 0 #a060ff;
  }
  50% {
    background-color: #a060ff;
    box-shadow: 20px 0 rgba(160, 95, 255, 0.25),
      -20px 0 rgba(160, 95, 255, 0.25);
  }
  100% {
    background-color: rgba(160, 95, 255, 0.25);
    box-shadow: 20px 0 #a060ff, -20px 0 rgba(160, 95, 255, 0.25);
  }
}
